export default {
    "i-list":`[
        {
            "id":1,
            "title":"规模和体量",
            "content":"在中国建立成最大的全国性商业平台"
        },
        {
            "id":2,
            "title":"商业创新",
            "content":"使得公司能够同过一种协调且多渠道的营销方式触达医院和KOL。同时，能够将标准化的医学/市场推广信息以更多样、灵活的方式传送到KOL手中"
        },
        {
            "id":3,
            "title":"执行效率",
            "content":"将传统的固定成本模式转换成可变成本模式，从而提高资源的使用效率"
        },
        {
            "id":4,
            "title":"绩效驱动模式",
            "content":"平台用户全部采用绩效驱动模式。在签订合约时，即确保严格执行绩效驱动模式"
        },
        {
            "id":5,
            "title":"盈利模式",
            "content":"通过收取服务费，市场调研费以及4期临床研究费用获得收益"
        },
        {
            "id":6,
            "title":"数据库和市场分析",
            "content":"构建医药行业最全面的数据库和市场信息。同时，为将来进一步提高效率提供数据支持"
        },
        {
            "id":7,
            "title":"平台和技术保护",
            "content":"平台的设计门槛高，很难被竞争对手复制"
        },
        {
            "id":8,
            "title":"市场相关性",
            "content":"平台与当前中国的商业格局相匹配。随着设计过程中的持续创新，能够确保平台的成长和稳固"
        },
        {
            "id":9,
            "title":"领导团队",
            "content":"公司由一队经验丰富的行业从业者所创建，同时吸引着来自制药，科技，咨询和商业等卓越领域的优秀人才"
        }
    ]`,
}