import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getLanguage } from './config/utils';
import commonEn from './locales/en-US/commonEn';
import commonCn from './locales/zh-CN/commonCn';
import homeEn from './locales/en-US/home';
import homeCn from './locales/zh-CN/home';
import investorsEn from './locales/en-US/investors';
import investorsCn from './locales/zh-CN/investors';
import factoryEn from './locales/en-US/factory';
import factoryCn from './locales/zh-CN/factory';
import agentsEn from './locales/en-US/agents';
import agentsCn from './locales/zh-CN/agents';

const resources = {
    en: {
        translation: {
            ...commonEn,
            ...homeEn,
            ...investorsEn,
            ...factoryEn,
            ...agentsEn
        }
    },
    zh: {
        translation: {
            ...commonCn,
            ...homeCn,
            ...investorsCn,
            ...factoryCn,
            ...agentsCn
        }
    },
};

i18n
  .use(initReactI18next) 
  .init({
    resources,
    lng: getLanguage(),
    keySeparator: false, 
    fallbackLng: "zh",
    debug: false,
    interpolation: {
      escapeValue: false, 
    },
  });

export default i18n;