export default {
    "f-list":`[
        {
            "id":1,
            "title":"市场的覆盖与拓展",
            "content":"以核心地区和医院为基础进行市场拓展——专注于品牌建设的市场拓展模式"
        },
        {
            "id":2,
            "title":"标准化医学信息的编撰、传递以及推广",
            "content":"在全国范围内，为企业品牌打造统一、专业的形象。在无论是否有医药推广人员覆盖的情况下，医疗相关人士都能获取到品牌标准化的内容"
        },
        {
            "id":3,
            "title":"合规管理",
            "content":"由富有行业经验的团队打造标准化流程并对活动进行数字化归档，确保与外资企业的合规要求相匹配"
        },
        {
            "id":4,
            "title":"数据报表",
            "content":"月度提供活动与绩效数据报告，用以支持企业决策"
        },
        {
            "id":5,
            "title":"商业优化",
            "content":"针对目标市场细分、销售、市场、 KAM和SFE提供全方位运营优化服务"
        },
        {
            "id":6,
            "title":"财务优势",
            "content":"更好的财务控制，促使花费更有效，更灵活。也就是说，资源可以选择在正确的时间、地点最有效的投入到相关决策环节"
        },
        {
            "id":7,
            "title":"商业创新",
            "content":"在多个领域进行创新，其中包括信息共享，市场调研，4期临床研究和数字营销领域"
        },
        {
            "id":8,
            "title":"数据集成和商业优化",
            "content":"通过综合重点商务渠道，推广活动以及竞品信息打造全球级的商业优化方案"
        },
        {
            "id":9,
            "title":"无自营销售团队的创新营销",
            "content":"通过利用医学信息，市场营销以及医疗相关人员的原创内容与目标医院和KOL进行有效的沟通"
        }
    ]`,
}