export default {
    "a-list":`[
        {
            "id":1,
            "title":"COMMERCIAL INNOVATION",
            "content":"The model will be the first major commercial innovation in China for many years which will allow many companies to address the recent market challenges. It will allow for the build of the single largest national commercial footprint in China."
        },
        {
            "id":2,
            "title":"GIG ECONOMY",
            "content":"The platform is driven off the GIG economy concept i.e. allows for people to earn income in a more flexible and material manner through leveraging ones capabilities and talent."
        },
        {
            "id":3,
            "title":"BUSINESS MODEL",
            "content":"Allows individuals to join and earn additional income in a flexible and transparent manner through leveraging their effort and relationships in the market. Key features include : Broad source of products i.e. suitable and allows for critical mass , rapid reimbursement , comprehensive commercial support , world class data and reporting tools."
        },
        {
            "id":4,
            "title":"SERVICES",
            "content":"The platform allows for benefits both for the agent and KOLs – this includes promotional fee to agents and market research and phase 4 opportunities for KOL’s. This will in effect strengthen their current relationships with HCP’s as well as build new relationships."
        },
        {
            "id":5,
            "title":"SUPPORT",
            "content":"The platform will allow for all key services to support the agents. This includes customer dB , all key medical and promotional materials , training system , compliance materials , marketing support and finally key SFE data which includes the supply of regular performance data."
        },
        {
            "id":6,
            "title":"KEY BENEFITS",
            "content":"YY provides an ability to earn ADDATIONAL , SUSTAINBALE INCOME in a way that is flexible and requires little additional resources and infrastructure. The platform is designed to service individuals and companies alike"
        },
        {
            "id":7,
            "title":"Flexibility",
            "content":"Earn additional income while not necessary be a formal staff of an entity and respect its time guidance and role expectations.  Paying no discretionary costs."
        },
        {
            "id":8,
            "title":"Reimbursement",
            "content":"with the potential to be reimbursed on a time manner by cutting off all layers between the key stakeholders (national distributor-regional distributor – municipal distributor – hospital) and capital flow."
        },
        {
            "id":9,
            "title":"Products",
            "content":"Get additional products without BD efforts which are hardly manageable for agents: explore, network, negotiate and report. "
        },
        {
            "id":10,
            "title":"Professionalism",
            "content":"Enhance his physicians’ relationship with good quality medical information while they provide academic detailing services."
        }
    ]`,
}