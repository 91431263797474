import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { Trans } from 'react-i18next';
import './copyright.less';

class Copyright extends Component {
    render() {

        const result = `common.${window.location.hostname}`

        return (
            <div className="copyright">
                <div className="footer_bt media_con">
                   <Row
                    gutter = {
                        { xs: 8, sm: 16, md: 24}
                    }
                   >
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}><Trans>common.add</Trans></Col>
                        <Col className="tx_right" xs={24} sm={24} md={12} lg={12} xl={12}><Trans>common.email</Trans>: info@youyaomedtech.com</Col>
                    </Row>
                    <Row className="color_6">
                        <Col span={24}>
                            <Trans>common.copyright</Trans>

                                <a rel="noopener noreferrer" target="_blank" href="//beian.miit.gov.cn/" >
                                    <img src="./icon-beian.png" alt="" />
                                    <Trans>{result}</Trans>
                                </a>
                                <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    href="//wap.scjgj.sh.gov.cn/businessCheck/verifKey.do?showType=extShow&serial=9031000020221031162604000009747700-SAIC_SHOW_310000-2c984b4c8bfadd5b018c351b62e91704610&signData=MEUCIEfDa1tNNi2lCVCz+Ck5NII+P1XSO0uOdFjCjMLs07QDAiEA76Tx2o9PEhj9MVa2ydQO9wBnR2yoFQgPRUOPpxc4YOg=">
                                    <img src="./icon-dz.jpg" alt="" />
                                    {/* 营业执照 */}
                                </a>
                            {/* <a href="http://218.242.124.22:8081/businessCheck/verifKey.do?showType=extShow&serial=9031000020170713143132000001922237-SAIC_SHOW_310000-4028e4cb666856620167991523403f47195&signData=MEUCIB1N2j8x7tSUAKgcL9YOIcf8hBCUg3/jE96JGdP3vHWyAiEAx1/bdOYm41yqMvy3C+JGUsG/iwJzIK1i/ePh3NJLqG8=">
                                <img src="http://img.youyao99.com/d0289dc0a46fc5b15b3363ffa78cf6c7.png" alt=""/>
                            </a> */}
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default Copyright;
