import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Layout } from 'antd';
import asyncImportComponent from '../config/asyncImportComp';
// 布局组件
import Nav from '../components/Nav.jsx';
import Copyright from '../components/Copyright.jsx';
import Footers from '../components/Footer.jsx';

const { Header, Content, Footer } = Layout;
// 路由
const Home = asyncImportComponent(() => import('../pages/home/Home.jsx'));
const Investors = asyncImportComponent(() => import('../pages/investors/Investors.jsx'));
const Factory = asyncImportComponent(() => import('../pages/factory/Factory.jsx'));
const Agents = asyncImportComponent(() => import('../pages/agents/Agents.jsx'));

class Index extends Component {
    // componentWillMount(){
    //     console.log(567)
    // }
    render() {
        return ( 
            <Layout className="layout">
                <Header style={{ position: 'fixed', zIndex: 100, width: '100%'}}>
                    <Nav></Nav>    
                </Header> 
                <Content>
                    <Switch>
                        <Route path="/" component={Home} exact></Route>
                        <Route path="/investors" component={Investors}></Route>
                        <Route path="/factory" component={Factory}></Route>
                        <Route path="/agents" component={Agents}></Route>
                    </Switch>
                </Content> 
                <Footer>
                    <Footers></Footers>
                    <Copyright></Copyright>    
                </Footer> 
            </Layout>
        )
    }
}
export default Index