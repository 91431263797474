export default {
    "i-list":`[
        {
            "id":1,
            "title":"SIZE & SCALE ",
            "content":"The model will allow for the build of the single largest national commercial footprint in China."
        },
        {
            "id":2,
            "title":"COMMERCIAL INNOVATION",
            "content":"Allows for a coordinated sale and multi-channel marketing approach for companies in engaging with hospitals and KOL’s. The platform allows for a consistent , more diverse / flexible model in engaging KOL’s with a medical / marketing message."
        },
        {
            "id":3,
            "title":"OPERATIONAL EFFICIENCES",
            "content":"the model allows for a core traditional fixed cost to be converted to a variable cost , thereby creating material operational efficiencies."
        },
        {
            "id":4,
            "title":"PERFORMANCE DRIVE MODEL",
            "content":"the model is designed to ensure that participants on the platform are performance driven i.e. strict performance measures to be agreed on signing."
        },
        {
            "id":5,
            "title":"SOURCES OF REVENUE ",
            "content":"The platform is such designed to generate revenue from service fees , market research  and phase 4 studies."
        },
        {
            "id":6,
            "title":"DB and MARKET INTELLIGENCE",
            "content":"The platform will allow for the building of the industry’s most comprehensive dB and market information. This will allow for the efficient use of data in further leveraging efficiency in the future."
        },
        {
            "id":7,
            "title":"PLATFORM & TECHNOLOGY PROTECTION",
            "content":"The platform has been such designed that the hurdles for competitors remain high and are difficult to copy."
        },
        {
            "id":8,
            "title":"MARKET RELEVANCE",
            "content":"The platform is currently relevant given the current commercial landscape in China. Going forward several innovations are in the design process that will ensure the growth and strengthening of the platform."
        },
        {
            "id":9,
            "title":"THE TEAM",
            "content":"The platform has been created by a team of experienced campaigners attracting leading skills form the Pharma , technology , consulting, and commercial excellence disciplines. "
        }
    ]`,
}