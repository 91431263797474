export default {
    "title":"医药·器械",
    "title-2":"学术营销平台",
    "subTitle":"优药为用户提供了一个创新型的平台充分利用用户自身的资源和能力帮助其拓展职业生涯并增加收入",
    "part-1":"我们的服务",
    "list-1":`[
        {
            "id":1,
            "title":"数据报表",
            "content":"月度提供活动与绩效数据报告，用以支持企业决策。"
        },
        {
            "id":2,
            "title":"合规管理",
            "content":"由富有行业经验的团队打造标准化流程并对活动进行数字化归档，确保与外资企业的合规要求相匹配。"
        },
        {
            "id":3,
            "title":"商业优化",
            "content":"针对目标市场细分、销售、市场、 KAM和SFE提供全方位运营优化服务。"
        }
    ]`,
    "part-2":"我们的优势",
    "list-2":`[
        {
            "id":1,
            "title":"数据集成和商业优化",
            "content":"通过综合重点商务渠道，推广活动以及竞品信息打造全球级的商业优化方案。"
        },
        {
            "id":2,
            "title":"标准化医学信息的编撰、传递以及推广",
            "content":"在全国范围内，为企业品牌打造统一、专业的形象。在无论是否有医药推广人员覆盖的情况下，医疗相关人士都能获取到品牌标准化的内容。"
        },
        {
            "id":3,
            "title":"商业创新",
            "content":"在多个领域进行创新，其中包括信息共享，市场调研，4期临床研究和数字营销领域。"
        }
    ]`,
    "part-3":"无论你是谁",
    "part-subTitle":"关注优药，关注学术推广",
    "list-3-1-title":"投资人",
    "list-3-1-content":"投资优药，把握医药未来营销",
    "list-3-2-title":"生产厂家",
    "list-3-2-content":"联手优药，打造全新销售渠道",
    "list-3-3-title":"区域合伙人",
    "list-3-3-content":"加入优药，获取优势资源红利"
}