import React, { Component } from 'react';
import Main from './routes/index';
import './App.less';
class App extends Component {
    render(){
        return (  
            <Main></Main>
        );
    }
}
export default App;