import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './i18n';
import './index.less';
import App from './App.jsx';
// import Routes from './routes/index';
import * as serviceWorker from './serviceWorker';
import 'core-js/es';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

ReactDOM.render( 
	<Router forceRefresh={true}>
		<App/>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();