import React, { useState,useEffect } from 'react';
import { Row, Col, List } from 'antd';
import { NavLink, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Nav.less';

function Nav() {
    const { t, i18n } = useTranslation();
    const [code,setCode] = useState(0);
    const [show,setShow] = useState(false);
    const storage = window.localStorage && window.localStorage.getItem('lng');
    useEffect(() => {
        if(storage){
            if(storage === 'zh'){
                setCode(0);
                i18n.changeLanguage('zh');
            } else {
                setCode(1);
                i18n.changeLanguage('en');
            }
        } else {
            setCode(0);
            window.localStorage && window.localStorage.setItem('lng','zh');
        }
    },[storage,i18n])
    // 切换语言
    const changeLang = (e) =>{
        const ev = e || window.event;
        const target = ev.target || ev.srcElement;
        if(target.nodeName.toLowerCase() === 'span'){
            if(target.innerHTML === '中'){
                window.localStorage && window.localStorage.setItem('lng','zh');
                window.location.href = '/';
                setCode(0);
                i18n.changeLanguage('zh');
            } else {
                window.localStorage && window.localStorage.setItem('lng','en');
                window.location.href = '/';
                setCode(1);
                i18n.changeLanguage('en');
            }
        }
    }
    // 显示导航菜单
    const toggleMenu = () => {
        if(show){
            setShow(false)
        } else {
            setShow(true)
        }
    }
    return (
        <>
            <div className="nav_phone_wrap">
                <Row className="nav_phone">
                    <Col sm={12} md={12} lg={12} xl={12} className="logo">
                        <Link to="/"><img src="http://img.youyao99.com/3e190b141ef7b234102e931f255c81db.png" alt=""/></Link>
                    </Col>
                    <Col sm={12} md={12} lg={12} xl={12} className="menu">
                        <i className="iconfont" onClick={() => toggleMenu()}>&#xe60e;</i>
                    </Col>
                </Row>
                <div className={show?"menu_list menu_list_show":"menu_list"}>
                    <List size="small">
                        <List.Item>
                            <NavLink to='/' exact activeClassName="active_nav">{t('menu.home')}</NavLink>
                        </List.Item>
                        {/* <List.Item>
                            <NavLink to='/investors' activeClassName="active_nav">{t('menu.investors')}</NavLink>
                        </List.Item> */}
                        <List.Item>
                            <NavLink to='/factory' activeClassName="active_nav">{t('menu.factory')}</NavLink>
                        </List.Item>
                        <List.Item>
                            <NavLink to='/agents' activeClassName="active_nav">{t('menu.agents')}</NavLink>
                        </List.Item>
                    </List>
                    <div className="lang" onClick={(e) => changeLang(e)}>
                        <span className={code === 0?"active_Lang":""}>中</span>
                        <span className={code === 1?"active_Lang":""}>EN</span>
                    </div>
                </div>
                
            </div>
            <Row className="nav">
                <Col sm={4} md={4} lg={4} xl={4} className="logo">
                    <Link to="/"><img src="http://img.youyao99.com/b5793ef82dfd79acaaff830c551dfc1d.png" alt=""/></Link>
                </Col>
                <Col sm={20} md={20} lg={20} xl={20}>
                    <Row className="nav_list">
                        <Col sm={22} md={22} lg={22} xl={20}>
                            <NavLink to='/' exact activeClassName="active_nav">{t('menu.home')}</NavLink>
                            {/* <NavLink to='/investors' activeClassName="active_nav">{t('menu.investors')}</NavLink> */}
                            <NavLink to='/factory' activeClassName="active_nav">{t('menu.factory')}</NavLink>
                            <NavLink to='/agents' activeClassName="active_nav">{t('menu.agents')}</NavLink>
                        </Col>
                        <Col sm={2} md={2} lg={2} xl={4} style={{textAlign:'right'}}>
                            <div className="lang" onClick={(e) => changeLang(e)}>
                                <span className={code === 0?"active_Lang":""}>中</span>
                                <span className={code === 1?"active_Lang":""}>EN</span>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    ); 
}
export default Nav;
