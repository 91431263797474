export default {
    // 导航
    "menu.home":"Home",
    "menu.about":"About us",
    "menu.investors":"Investors",
    "menu.factory":"Manufacturer",
    "menu.agents":"Regional Partners",
    "menu.news":"News",
    // 底部
    "footer.contact":"Contact",
    "footer.wx":"YouYao WeChat",
	"footer.yjy":"YouJiaYi WeChat",
    // 底部版权
    "common.email":"Email",
    "common.phonenum":"Phone Number",
    "common.add":"Address: Room 7310, Building A7, Daning Central Plaza, No.718 Lingshi Road, Jing 'an District, Shanghai",
    "common.copyright":"Copyright 2020-2023 YouYao reserved.",
    "common.copyright2":"沪ICP备17029781号-6",
    "common.localhost":" 沪ICP备2023008356号-1",

    "common.youyaopharma.com":" 沪ICP备2023008356号-1",
    "common.www.youyaopharma.com":" 沪ICP备2023008356号-1",

    "common.youyaomedtech.com":"沪ICP备2023008356号-3",
    "common.www.youyaomedtech.com":"沪ICP备2023008356号-3",
}
