export default {
    "title":"Drugs & Devices",
    "title-2":"Academic Marketing Platform",
    "subTitle":"YouYao, the innovative platform which provides opportunity for your career to increase the income by your own resource and your own ability.",
    "part-1":"Services",
    "list-1":`[
        {
            "id":1,
            "title":"DATA REPORTING",
            "content":"All activities and performance outputs to be reported in accordance with MNC reporting – this will be supplied to companies monthly."
        },
        {
            "id":2,
            "title":"COMPLIANCE",
            "content":"Same compliance protocol of MNCs to be followed by experienced team with SOPs, activities well documented digitally."
        },
        {
            "id":3,
            "title":"COMMERCIAL OPTIMIZATION",
            "content":"Full blown operation optimization services in terms of targeting,segmentation, S & M, KAM and SFE"
        }
    ]`,
    "part-2":"Advantage",
    "list-2":`[
        {
            "id":1,
            "title":"DATA INTEGRATION AND COMMERCIAL EXCELLENCE",
            "content":"The platform allows for a world class commercial excellence program thru the integration of key commercial , activity and competitor information."
        },
        {
            "id":2,
            "title":"CONSISTENT MESSAGING,PROMOTION and MEDICAL CONTENT",
            "content":"Allows for a uniform and professional representation of the company and its brands across China. HCP’s will receive the same content and messaging regardless of the person/s covering them."
        },
        {
            "id":3,
            "title":"COMMERCIAL INNOVATIONS",
            "content":"The platform will allow for the introduction of several key innovations which includes information sharing , market research . phase 4 studies and digital marketing activities."
        }
    ]`,
    "part-3":"Anyone",
    "part-subTitle":"Follow YouYao WeChat, focus on academic promotion.",
    "list-3-1-title":"To Investor",
    "list-3-1-content":"Invest YouYao, to grasp the marketing for future medicine",
    "list-3-2-title":"To Manufacturer",
    "list-3-2-content":"Cooperate with YouYao, to build a fresh channel for sales",
    "list-3-3-title":"To Regional Partners",
    "list-3-3-content":"Join in YY, to receive the benefit of advantage resources"
}