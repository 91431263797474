import React, { Component } from 'react';
import { Row, Col } from 'antd';
// import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import "animate.css";
import './footer.less';
class Footer extends Component {
    render() {
        return (
            <div className="footer_bd">
                <div className="footer_bd_con">
                    <h6><Trans>footer.contact</Trans></h6>
                    <Row>
                        <Col xs={12} sm={8} md={8} lg={8} xl={8}>
                            <div className="ft_pic">
                                <img src="http://img.youyao99.com/10054ea87aebf64b0b5ccdaca2088d21.png" alt=""/>
                            </div>
                            <p><Trans>common.phonenum</Trans></p>
                            <p>17701804774</p>
                        </Col>
                        <Col xs={12} sm={8} md={8} lg={8} xl={8}>
                            <div className="ft_pic">
                                <img src="http://img.youyao99.com/550a2d2852b355b0414b22a2602fc3b8.png" alt=""/>
                            </div>
                            <p style={{marginTop:'30px'}}><Trans>common.email</Trans></p>
                            <p>info@youyaomedtech.com</p>
                        </Col>
                        <Col xs={12} sm={8} md={8} lg={8} xl={8}>
							<div className="wx_pic">
								<div className="ft_pic_wrap">
									<div className="ft_pic wx_code">
										<img src="http://img.youyao99.com/d7ae5379eaa811626ce3461bd02a302d.jpg" alt=""/>
									</div>
									<Trans>footer.wx</Trans>
								</div>
								<div className="ft_pic_wrap">
									<div className="ft_pic wx_code">
										<img src="http://img.youyao99.com/4627a0d588dd2d5411227f57cb6876b5.jpg" alt=""/>
									</div>
									<Trans>footer.yjy</Trans>
								</div>
							</div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default Footer;