export default {
    "a-list":`[
        {
            "id":1,
            "title":"商业创新",
            "content":"中国近十年首次重大商业创新，以协助众多制药公司应对目前市场的挑战。而最终成为中国市场上唯一的，最大的全国性商业平台"
        },
        {
            "id":2,
            "title":"共享经济",
            "content":"平台脱胎于共享经济的概念，即允许人们以更灵活和更物质的方式通过自己的能力和才干来赚取收入"
        },
        {
            "id":3,
            "title":"商业模式",
            "content":"以个人身份加入平台，并通过自身的工作和市场资源，以灵活和透明的方式获得额外收入。主要特点包括：广泛的产品，合作灵活性高，允许群聚效应，快速结算，全面的商业支持，全球性的数据与报表系统"
        },
        {
            "id":4,
            "title":"服务",
            "content":"代理人和KOL双赢——代理商获得推广费用，KOL获得市场调研和4期临床研究的机会。这将有效地加强代理人与医疗相关人员的关系，同时也可建立新的关系"
        },
        {
            "id":5,
            "title":"支持",
            "content":"平台为代理人提供了所有需要的服务。例如数据，关键医学推广材料，培训，合规材料，市场营销支持，以及最关键的SFE数据支持，包括提供定期的业绩数据"
        },
        {
            "id":6,
            "title":"主要优势",
            "content":"优药提供了一种赚取额外且是可持续收入的能力，灵活且无需额外的资源和成本投入。平台同时为个人和为公司提供服务"
        },
        {
            "id":7,
            "title":"灵活合作",
            "content":"无需全职，既能赚取额外收入，符合代理人对时间、角色的控和预期，无需支付额外费用"
        },
        {
            "id":8,
            "title":"快速结算",
            "content":"通过打破关键利益相关者（总代——区域代——市代——医院）和资金流动之间的固有层级，使得针对代理人能够快速结算"
        },
        {
            "id":9,
            "title":"便捷选择",
            "content":"无需商业谈判即可签约产品，这对于代理人来说很难做到，例如找产品，铺渠道，商业谈判和汇报"
        },
        {
            "id":10,
            "title":"专业拜访",
            "content":"在学术拜访过程中，为医生提供高质量的专业学术信息，从而加强与客户的关系"
        }
    ]`,
}