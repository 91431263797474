export default {
    // 导航
    "menu.home": "首页",
    "menu.investors": "投资人",
    "menu.factory": "生产厂家",
    "menu.agents": "区域合伙人",
    // 底部
    "footer.contact": "联系我们",
    "footer.wx": "优药公众号",
    "footer.yjy": "优佳医公众号",
    // 底部版权
    "common.email": "邮箱",
    "common.phonenum": "电话",
    "common.add": "地址: 上海市静安区灵石路718号大宁中心广场三期A7栋7310室",
    "common.copyright": "Copyright 2020-2023 优药平台. ",
    "common.copyright2": "沪ICP备17029781号-6",
    "common.localhost": " 沪ICP备2023008356号-1",

    "common.youyaopharma.com": " 沪ICP备2023008356号-1",
    "common.www.youyaopharma.com": " 沪ICP备2023008356号-1",

    "common.youyaomedtech.com": "沪ICP备2023008356号-3",
    "common.www.youyaomedtech.com": "沪ICP备2023008356号-3",
}
