export default {
    "f-list":`[
        {
            "id":1,
            "title":"MARKET COVERAGE & EXPANSION",
            "content":" Allows for an expanded presence across key geographies & hospitals – allows for an expanded growth model and focus on core investment brands."
        },
        {
            "id":2,
            "title":"CONSISTENT MESSAGING , PROMOTION and MEDICAL CONTENT",
            "content":"Allows for a uniform and professional representation of the company and its brands across China. HCP’s will receive the same content and messaging regardless of the person/s covering them."
        },
        {
            "id":3,
            "title":"COMPLIANCE ",
            "content":"Same compliance protocol of MNCs to be followed by experienced team with SOPs, activities well documented digitally."
        },
        {
            "id":4,
            "title":"DATA REPORTING",
            "content":"All activities and performance outputs to be reported in accordance with MNC reporting – this will be supplied to companies monthly."
        },
        {
            "id":5,
            "title":"COMMERCIAL OPTIMIZATION",
            "content":"Full blown operation optimization services in terms of targeting,segmentation, S & M, KAM and SFE"
        },
        {
            "id":6,
            "title":"FINANCIAL ",
            "content":"The model allows for a more cost efficiency , flexibility, and good financial control i.e. resources can be allocated to the right stakeholders for right activities at right place and time."
        },
        {
            "id":7,
            "title":"COMMERCIAL INNOVATIONS",
            "content":"The platform will allow for the introduction of several key innovations which includes information sharing , market research . phase 4 studies and digital marketing activities."
        },
        {
            "id":8,
            "title":"DATA INTEGRATION AND COMMERCIAL EXCELLENCE",
            "content":"The platform allows for a world class commercial excellence program thru the integration of key commercial , activity and competitor information."
        },
        {
            "id":9,
            "title":"NON-SALES CHANNEL INNOVATIONS",
            "content":"The platform allows for medical , marketing and HCP generated content to be leveraged and efficiently communicated with target hospitals and KOL’s."
        }
    ]`,
}